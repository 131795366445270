import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import * as SubscriptionService from '@nordic-web/rest-codegen/generated/subscription'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { useDidUpdateEffect } from '@nordic-web/utils/hooks/use-did-update-effect'
import { authenticationStore, useAuthenticationStore } from '@/features/auth/authentication-store'
import { voucherQueryKey, withSubscriptionApiErrorCallback } from '@/features/checkout/hooks/use-subscription'
import { nextConfig } from '@/helpers/env'
import { stringFromQueryParam } from '@/utils/query-string'

SubscriptionService.OpenAPI.BASE = nextConfig.string('SUBSCRIPTION_API') + '/v1'

export const useCheckoutPage = (entitlementId?: string) => {
  const router = useRouter()
  const packageId = entitlementId || (stringFromQueryParam(router.query?.packageId) ?? '')
  const { isLoggedIn } = useAuthenticationStore()
  const queryClient = useQueryClient()

  const checkoutPageQuery = useQuery({
    queryKey: ['checkout', packageId],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getCheckoutPage({
        packageId,
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  useDidUpdateEffect(() => {
    if (isLoggedIn && checkoutPageQuery.data) {
      checkoutPageQuery.refetch()
      if (router.query.voucherCode) {
        queryClient.fetchQuery({ queryKey: voucherQueryKey })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, isLoggedIn)

  return checkoutPageQuery
}
