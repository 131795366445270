const alphaMap = [100, 90, 80, 70, 60, 50, 40, 30, 25, 20, 15, 10, 5] as const
type alphaTypes = (typeof alphaMap)[number]

const white = (alpha: alphaTypes) => `hsla(0, 0%, 100%, ${alpha / 100})`

const black = (alpha: alphaTypes) => `hsla(0, 0%, 0%, ${alpha / 100})`

type ColorMap = {
  [key in `white${alphaTypes}` | `black${alphaTypes}`]: string
}

export const whiteColors: ColorMap = alphaMap.reduce<ColorMap>((acc, alpha) => {
  acc[`white${alpha}` as keyof ColorMap] = white(alpha)
  return acc
}, {} as ColorMap)

export const blackColors: ColorMap = alphaMap.reduce<ColorMap>((acc, alpha) => {
  acc[`black${alpha}` as keyof ColorMap] = black(alpha)
  return acc
}, {} as ColorMap)

export const colorPrimitives = {
  redLighter: '#FDAFAF',
  redLight: '#FF575F',
  red1: '#E0001C',
  red2: '#C40317',
  red3: '#99000F',
  red4: '#750308',
  red5: '#5C0505',
  red6: '#400500',
  red7: '#2E0400',
  red8: '#1C0200',
  blue1: '#006EFA',
  blue2: '#004AFC',
  blue3: '#0E2ADD',
  blue4: '#0019BD',
  blue5: '#000A91',
  blue6: '#000A6E',
  blue7: '#00054D',
  blue8: '#02002E',
  grey1: '#4A4A4D',
  grey2: '#363639',
  grey3: '#2A2A2D',
  grey4: '#1F1F21',
  grey5: '#171719',
  grey6: '#0D0D0F',
  information: '#3399FF',
  success: '#05C776',
  attention: '#FFE052',
  error: '#FC545A',
  ...whiteColors,
  ...blackColors,
}
