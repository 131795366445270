import React, { useEffect } from 'react'
import styled from 'styled-components'
import type { PageInfoFieldsFragment } from '@nordic-web/gql'
import { Stack } from '@nordic-web/ui-components'
import { InfiniteScrollContainer } from '@/components/infinite-scroll-container'
import { PageMargin } from '@/components/scaffolding/page-margin'
import { PanelFactory, wantsFullWidth } from '@/features/panel-factory/panel-factory'
import type { PanelItem } from '@/features/panel-factory/types'
import { HeaderSpacing } from '@/layouts/header/header-container'
import { PanelTracking } from '@/tracking/panel-tracking'

type PanelListProps = {
  panels: PanelItem[]
  loadMoreItems?: (offset: number) => void
  nextPageInfo?: PageInfoFieldsFragment
  hidePromoCdpButton?: boolean
  cardQueryParameters?: object
  topLeftContent?: React.ReactNode
  adjustHeaderMargins: boolean
}

export const PanelList = ({
  topLeftContent,
  panels: _panels,
  loadMoreItems,
  nextPageInfo,
  hidePromoCdpButton,
  cardQueryParameters,
  adjustHeaderMargins,
}: PanelListProps) => {
  const panels = _panels.filter((item) => {
    const isEmptyPanel = item && 'content' in item && item.content.pageInfo.totalCount === 0
    return !isEmptyPanel
  })

  useEffect(() => {
    // Trigger infinite scroll if we havent loaded enough content to cover the screen
    const hasEnoughContentLoaded = document.body.scrollHeight > document.body.clientHeight
    if (!hasEnoughContentLoaded) {
      window.dispatchEvent(new CustomEvent('scroll'))
    }
  }, [panels])

  const isFirstPanelASinglePanel = panels[0]?.__typename === 'SinglePanel'

  return (
    <>
      {!isFirstPanelASinglePanel && adjustHeaderMargins && <HeaderSpacing />}

      <InfiniteScrollContainer
        // We need to check _panels length here, otherwise infinite scroll stops working if a window has no content and is filtered out completely
        dataLength={_panels.length}
        hasMore={!!nextPageInfo?.hasNextPage}
        next={() => {
          if (nextPageInfo?.nextPageOffset) {
            loadMoreItems?.(nextPageInfo.nextPageOffset)
          }
        }}
      >
        {topLeftContent && (
          <TopLeftContainer shouldTakeSpace={!isFirstPanelASinglePanel}>{topLeftContent}</TopLeftContainer>
        )}
        <Stack nwGap={[8, 12]}>
          {panels.map((panel, index) => {
            const typeName = panel.__typename
            const hasPlaylist = typeName === 'ClipsPanel'
            const isFullWidth = wantsFullWidth(panel)

            return (
              <PageMargin key={panel.id} applyMargin={!isFullWidth}>
                <PanelTracking panel_row_index={index} panel_id={panel.id}>
                  <PanelFactory
                    hidePromoCdpButton={hidePromoCdpButton}
                    cardQueryParameters={{
                      ...(cardQueryParameters ?? {}),
                      playlist: hasPlaylist && panel.id,
                    }}
                    panel={panel}
                    panel_row_index={index}
                  />
                </PanelTracking>
              </PageMargin>
            )
          })}
        </Stack>
      </InfiniteScrollContainer>
    </>
  )
}

type TopLeftContainerProps = {
  shouldTakeSpace: boolean
}

const TopLeftContainer = styled.div<TopLeftContainerProps>(({ shouldTakeSpace, theme }) => ({
  position: shouldTakeSpace ? 'static' : 'absolute',
  width: '100%',
  top: 'calc(var(--header-height) + var(--page-top-padding))',
  zIndex: 100,
  marginBottom: theme.space(5),
}))
