import { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { Button, Stack, TypographyText } from '@nordic-web/ui-components'
import { MaxWidthContainerLg } from '@/components/max-width-container/max-width-container-lg'
import { CenterPage } from '@/components/scaffolding/center'
import { DefaultSeoTags } from '@/components/seo/default-seo-tags'
import { paths } from '@/helpers/paths'
import { dataLayerPush } from '@/tracking/tracking'

export const Error404 = () => {
  const t = useTranslations()

  useEffect(() => {
    dataLayerPush({
      event: 'pageError',
      errorData: {
        code: '404',
        pathname: window?.location?.pathname ?? undefined,
      },
    })
  }, [])

  return (
    <>
      <DefaultSeoTags />
      <CenterPage>
        <MaxWidthContainerLg>
          <Stack nwGap={4} nwAlignItems="center">
            <TypographyText as="h1" nwVariant="title2">
              {t('error_page__not_found_title')}
            </TypographyText>
            <Stack nwGap={8}>
              <TypographyText nwVariant="body2">
                {t.rich('error_page__not_found_description', {
                  br: () => <br />,
                })}
              </TypographyText>
              <Link href={paths.start.urlString()}>
                <Button as="a">{t('error_page__not_found_button')}</Button>
              </Link>
            </Stack>
          </Stack>
        </MaxWidthContainerLg>
      </CenterPage>
    </>
  )
}
