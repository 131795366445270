import { useTranslations } from 'next-intl'
import { Avatar, Stack, TypographyText } from '@nordic-web/ui-components'
import { useHasChildProfilesEnabled } from '@nordic-web/utils/hooks/use-has-child-profile-enabled'
import { handleEnterKeyDown } from '@nordic-web/utils/misc/keyboard'
import { authenticationStore } from '@/features/auth/authentication-store'
import { useProfileUpgradeMessage } from '@/features/profiles/hooks/use-profile-upgrade-message'
import { useUserQuery } from '@/features/profiles/hooks/use-user-query'
import { MAX_PROFILES, MIN_PROFILES } from '@/layouts/header/constants'
import { MobileMenuProfileButton } from './style'

type Props = {
  onProfileSelect: () => void
}

export const MobileMenuProfiles = ({ onProfileSelect }: Props) => {
  const { data, isLoading } = useUserQuery()
  const { hasChildProfilesEnabled } = useHasChildProfilesEnabled()
  const { showProfileUpgradeMessage } = useProfileUpgradeMessage()
  const t = useTranslations()
  const profiles = data?.user?.profiles || []
  const activeProfileId = data?.user?.activeProfile?.id
  const shouldShowProfiles = profiles.length <= MAX_PROFILES && profiles.length > MIN_PROFILES

  if (isLoading || !shouldShowProfiles) return null

  return (
    <Stack nwGap={4}>
      <TypographyText nwVariant="label" nwColor="secondary">
        {t('main_menu__change_profile')}
      </TypographyText>
      {profiles
        .filter(({ id }) => id !== activeProfileId)
        .map(({ id, name, avatarUrl, isChild }) => {
          const isDisabled = isChild && !hasChildProfilesEnabled

          const onClick = async () => {
            if (isDisabled) {
              showProfileUpgradeMessage()
              return
            }
            await authenticationStore.changeProfile(id, isChild)
            onProfileSelect()
          }

          return (
            <MobileMenuProfileButton key={id} tabIndex={0} onClick={onClick} onKeyDown={handleEnterKeyDown(onClick)}>
              <Stack nwDirection="row" nwAlignItems="center" nwGap={4}>
                <Avatar nwName={name} nwImageUrl={avatarUrl} nwSize="mini" nwAxis="x" nwDisabled={isDisabled} />
                <TypographyText nwVariant="body2">{name}</TypographyText>
              </Stack>
            </MobileMenuProfileButton>
          )
        })}
    </Stack>
  )
}
